import React, { useMemo, useState } from 'react'
import Select from '../../../common/Select'
import Alert from '@mui/material/Alert'
import { IconButton, Tooltip, Typography, Button } from '@mui/material'
// eslint-disable-next-line no-unused-vars
import { chewAPIEcgDataToForm, getConsentFormValidation } from '../../../../utils/validationSchemas'
import { useFormik } from 'formik'
import { makeStyles } from '@mui/styles'
import TextField from '../../../common/TextField'
import Checkbox from '../../../common/Checkbox'
import Switch from '../../../common/Switch'
import { calculateAge, daysInMonth, months, years } from '../../../../utils/dates'
import { chewDataForAPIUpdate, hasUnsavedData, isEditable, persistUnsavedECGFormData } from '../../../../utils/ecg'
import { useConnect } from 'redux-bundler-hook'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import {
  backupParamsValues,
  checkReasonOptions,
  frequencyUnitsOptions,
  getParamtersDisabledInitialState,
  orderedAilments,
  rythmOptions
} from '../../../../utils/formVars'
import { chunkify, noop } from '../../../../utils/misc'
import DoctorActions from '../actions/DoctorActions'
import DefaultActions from '../actions/DefaultActions'
import AdminActions from '../actions/AdminActions'
import { OCRReportDialog } from '../../../TableWeb/EditDialog/PatientData/EditForm'

const actionsByKind = {
  0: AdminActions,
  1: AdminActions,
  2: DoctorActions,
  3: DefaultActions,
}

const toTitleCase = str => str.replace(
  /\w\S*/g,
  function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  }
)

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 35,
  },
  fullName: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  },
  verticalGap: {
    marginBottom: 15,
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  verticalGapLarge: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
  },
  birthDate: {
    display: 'flex',
    flexDirection: 'column',
    gap: 25,
    width: '100%',
    minWidth: '100%',
    alignItems: 'left',
    marginTop: 10,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
  },
  rowSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  actionsColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 20,
    marginTop: 20,
    alignItems: 'left',
  },
  rythmRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  intervalsRowOne: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10%',
    alignItems: 'center',
    justifyContent: 'start',
    width: '100%',
  },
  thirdData: {
    display: 'flex',
    flexDirection: 'column',
    gap: 25,
    alignItems: 'left',
  },
  previousEcg: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  previousEcgData: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 25,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  ailmentColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    width: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  newOrExisting: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 300,
    gap: 10,
  },
  createButton: {
    minWidth: 200,
    minHeight: 200,
  },
}))

const PatientDataFormEdit = props => {
  const {
    ecg,
    kind,
    confirmationDialogPref,
    isUnsaved,
    doEditECG,
    doSetSelectedEcg,
    doSetSnackbarFail,
    doUpdateUrl,
    doResetEcgDialogData,
    doSendOcrErrorReport,
  } = props

  const [ocrReportDialogOpen, setOcrReportDialogOpen] = useState(false)
  const initialParamsDisabled = getParamtersDisabledInitialState(ecg || {})
  const [paramsDisabled, setParamsDisabled] = useState(initialParamsDisabled)
  const paramsBackup = backupParamsValues(ecg)
  const classes = useStyles()
  const lastUnsavedChanges = new Date(ecg?.lastChanged ?? Date.now())

  const onSubmit = async (values) => {
    const newVals = chewDataForAPIUpdate(values)
    if (kind === 2 && newVals.diagnosis === '') {
      doSetSnackbarFail('Conclusiones es un campo requerido.')
      return false
    }
    const res = await doEditECG(newVals, newVals.id, true)
    if (res) {
      doSetSelectedEcg(res)
    }
    return !!res
  }

  const formik = useFormik({
    // Disable validations until we solve how to dynamically change mandatory fields for every moment of the ECG lifecycle
    // validationSchema: getConsentFormValidation, 
    initialValues: ecg,
    onSubmit: onSubmit,
  })
  const { years: ageYears, months: ageMonths } = calculateAge(formik.values.birthDay, formik.values.birthMonth, formik.values.birthYear)

  const onChange = event => {
    formik.handleChange(event)
    persistUnsavedECGFormData(
      formik.values,
      event.target.value,
      event.target.name,
    )
  }

  const chunks = chunkify(orderedAilments, 5, true)
  const ailmentCheckboxes = useMemo(() => chunks.map((chunk, index) => (
    <div key={`mobile-ailments-col-${index + 1}`} className={classes.row}>
      {chunk.map(ailment => {
        const handleChange = isEditable(kind, ailment.name) ? onChange : noop
        return (
          <div key={ailment.name} style={{ maxWidth: '50%', minWidth: '50%' }}>
            <Checkbox
              name={ailment.name}
              checked={ecg[ailment.name]}
              label={ailment.displayName}
              labelPlacement='end'
              color='secondary'
              onChange={handleChange}
              size='medium'
            />
          </div>
        )
      })}
    </div>
  )), [ecg])
  const Actions = actionsByKind[kind]
  const handleDoesntApplyCheckboxChange = async e => {
    const raisingEdge = !paramsDisabled[e.target.name] && e.target.checked
    const newValue = raisingEdge ? '-' : paramsBackup[e.target.name]
    await setParamsDisabled({
      ...paramsDisabled,
      [e.target.name]: e.target.checked,
    })
    await formik.setValues({
      ...formik.values,
      [e.target.name]: newValue,
    })
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <OCRReportDialog
        open={ocrReportDialogOpen}
        onClose={() => setOcrReportDialogOpen(false)}
        onSubmit={message => doSendOcrErrorReport(ecg.id, message)}
      />
      <div name='patient-data' className={classes.root}>
        <div name='fullname' className={classes.column}>
          <div className={classes.row}>
            <Typography
              style={{
                fontWeight: 'bold',
                fontSize: '1.2rem',
                color: '#486581',
              }}
            >
              DATOS DEL PACIENTE
            </Typography>
            {isUnsaved && (
              <Tooltip arrow placement='bottom' title='Deshacer cambios'>
                <IconButton
                  onClick={() => {
                    const data = doResetEcgDialogData(ecg)
                    const formData = chewAPIEcgDataToForm(data)
                    formik.setValues(formData)
                  }}
                >
                  <RestartAltIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
          {isUnsaved && (
            <Typography fontFamily='roboto' fontStyle='italic' fontSize={12}>
              Cambios no guardados desde {lastUnsavedChanges.toLocaleString()}
            </Typography>
          )}
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '1.1rem',
              color: '#707070',
            }}
          >
            Nombre completo
          </Typography>
          <div className={classes.fullName}>
            <TextField
              margin='dense'
              disabled={!isEditable(kind, 'name')}
              autoFocus
              placeholder='Nombre Completo'
              name='name'
              rounded
              dense
              fullWidth
              value={formik.values.name}
              onValueChange={onChange}
            />
          </div>
        </div>
        <div name='birthDate' className={classes.verticalGap}>
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '1.1rem',
              color: '#707070',
            }}
          >
            Fecha de Nacimiento
          </Typography>
          <div className={classes.birthDate}>
            <Select
              placeholder='Día'
              fullWidth
              name='birthDay'
              options={() => {
                const totalDays = daysInMonth(formik.values.birthMonth, formik.values.birthYear) || 31
                const daysArr = Array.from(Array(totalDays).keys())
                return daysArr.map(n => (
                  { key: `day-${n + 1}`, displayValue: n + 1, value: n + 1 }
                ))
              }}
              dense
              onValueChange={onChange}
              disabled={!isEditable(kind, 'birthDay')}
              value={formik.values.birthDay}
              width='100%'
            />
            <Select
              placeholder='Mes'
              dense
              name='birthMonth'
              disabled={!isEditable(kind, 'birthMonth')}
              options={() => {
                return months.map(month => (
                  { key: month.name, displayValue: month.name, value: month.value }
                ))
              }}
              onValueChange={onChange}
              value={formik.values.birthMonth}
              width='100%'
            />
            <Select
              placeholder='Año'
              dense
              name='birthYear'
              disabled={!isEditable(kind, 'birthYear')}
              options={() => {
                return years.map(year => (
                  { key: `year-${year}`, displayValue: year, value: year }
                ))
              }}
              onValueChange={onChange}
              value={formik.values.birthYear}
              width='100%'
            />
            <Typography
              fontSize={20}
              fontWeight={100}
            >
              Edad: {ageYears} años {ageMonths} meses
            </Typography>
          </div>
        </div>
        <div className={classes.column}>
          <div className={classes.fullWidth}>
            <div className={classes.verticalGap}>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.1rem',
                  color: '#707070',
                }}
              >
                Sexo
              </Typography>
              <Select
                dense
                value={formik.values.sex}
                disabled={!isEditable(kind, 'sex')}
                name='sex'
                options={[
                  { key: 'FEMENINO', displayValue: 'Femenino', value: 'FEMENINO' },
                  { key: 'MASCULINO', displayValue: 'Masculino', value: 'MASCULINO' },
                ]}
                onValueChange={onChange}
                width='100%'
              />
            </div>
          </div>
          <div className={classes.fullWidth}>
            <div className={classes.verticalGap}>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.1rem',
                  color: '#707070',
                }}
              >
                Presión Arterial
              </Typography>
              <div className={classes.row}>
                <TextField
                  type='number'
                  margin='dense'
                  placeholder='TAS'
                  name='tas'
                  dense
                  disabled={!isEditable(kind, 'tas')}
                  rounded
                  fullWidth
                  value={formik.values.tas}
                  onValueChange={onChange}
                />
                <Typography sx={{ fontSize: '2rem' }}>
                  {'/'}
                </Typography>
                <TextField
                  type='number'
                  margin='dense'
                  placeholder='TAD'
                  disabled={!isEditable(kind, 'tad')}
                  name='tad'
                  dense
                  rounded
                  fullWidth
                  value={formik.values.tad}
                  onValueChange={onChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.fullWidth}>
            <div className={classes.verticalGap}>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.1rem',
                  color: '#707070',
                }}
              >
                Estatura
              </Typography>
              <div>
                <TextField
                  margin='dense'
                  placeholder='cm'
                  name='height'
                  disabled={!isEditable(kind, 'height')}
                  type='number'
                  rounded
                  adornmentPosition='end'
                  endAdornmentText='cm'
                  dense
                  fullWidth
                  value={formik.values.height}
                  onValueChange={onChange}
                />
              </div>
            </div>
          </div>
          <div className={classes.fullWidth}>
            <div className={classes.verticalGap}>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.1rem',
                  color: '#707070',
                }}
              >
                Peso
              </Typography>
              <div>
                <TextField
                  margin='dense'
                  placeholder='kg'
                  disabled={!isEditable(kind, 'weight')}
                  name='weight'
                  type='number'
                  adornmentPosition='end'
                  endAdornmentText='kg'
                  rounded
                  dense
                  fullWidth
                  value={formik.values.weight}
                  onValueChange={onChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.column}>
          <div className={classes.fullWidth}>
            <div className={classes.verticalGap}>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.1rem',
                  color: '#707070',
                }}
              >
                Email del paciente
              </Typography>
              <div>
                <TextField
                  margin='dense'
                  type='email'
                  disabled={!isEditable(kind, 'email')}
                  placeholder='Email'
                  name='email'
                  rounded
                  dense
                  fullWidth
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && formik.touched.email && Boolean(formik.errors.email && formik.errors.email)}
                  helperText={formik.touched.email && formik.touched.email && formik.errors.email && formik.errors.email}
                  helperTextStyles={{ color: 'red', fontWeight: 800 }}
                  onValueChange={onChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div name='patientHistory' className={classes.verticalGapLarge}>
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: '1.2rem',
            color: '#486581',
          }}
        >
          HISTORIAL
        </Typography>
        <div className={classes.verticalGap}>
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '1.1rem',
              color: '#707070',
            }}
          >
            Padecimientos
          </Typography>
          <div className={classes.column}>
            {ailmentCheckboxes}
          </div>
        </div>
        <div className={classes.verticalGap}>
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '1.1rem',
              color: '#707070',
            }}
          >
            Marcapaso
          </Typography>
          <div className={classes.fullWidth}>
            <div className={classes.column}>
              <Switch
                name='pacemaker'
                checked={formik.values.pacemaker}
                onChange={onChange}
                disabled={!isEditable(kind, 'pacemaker')}
              />
              {formik.values.pacemaker && (
                <div style={{ width: '100%' }}>
                  <TextField
                    margin='dense'
                    placeholder='Tipo de marcapaso'
                    name='pacemakerKind'
                    disabled={!isEditable(kind, 'pacemakerKind')}
                    rounded
                    dense
                    fullWidth
                    value={formik.values.pacemakerKind}
                    onValueChange={onChange}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '1.1rem',
              color: '#707070',
            }}
          >
            ECG Previo
          </Typography>
          <div className={classes.previousEcg}>
            <Switch
              name='previousEcg'
              checked={Boolean(formik.values.previousEcg)}
              disabled={!isEditable(kind, 'previousEcg')}
              onChange={async event => {
                if (!event.target.checked) {
                  await formik.setValues({
                    ...formik.values,
                    lastECGDay: 1,
                    lastECGMonth: 1,
                    lastECGYear: 2022,
                    previousEcg: false,
                    resultNormal: true,
                  })
                }
                onChange(event)
              }}
            />
            {formik.values.previousEcg && (
              <div className={classes.row} style={{ marginTop: 10, marginBottom: 15 }}>
                <div className={classes.previousEcgData}>
                  <div>
                    <Typography fontSize={14}>
                      Último estudio
                    </Typography>
                  </div>
                  <Select
                    placeholder='Día'
                    name='lastECGDay'
                    disabled={!isEditable(kind, 'lastECGDay')}
                    options={() => {
                      const totalDays = daysInMonth(formik.values.birthMonth, formik.values.birthYear) || 31
                      const daysArr = Array.from(Array(totalDays).keys())
                      return daysArr.map(n => (
                        { key: `day-${n + 1}`, displayValue: n + 1, value: n + 1 }
                      ))
                    }}
                    dense
                    onValueChange={onChange}
                    value={formik.values.lastECGDay}
                    width='100%'
                  />
                  <Select
                    placeholder='Mes'
                    dense
                    name='lastECGMonth'
                    disabled={!isEditable(kind, 'lastECGMonth')}
                    options={() => {
                      return months.map(month => (
                        { key: month.name, displayValue: month.name, value: month.value }
                      ))
                    }}
                    onValueChange={onChange}
                    value={formik.values.lastECGMonth}
                    width='100%'
                  />
                  <Select
                    placeholder='Año'
                    dense
                    name='lastECGYear'
                    disabled={!isEditable(kind, 'lastECGYear')}
                    options={() => {
                      return years.map(year => (
                        { key: `year-${year}`, displayValue: year, value: year }
                      ))
                    }}
                    onValueChange={onChange}
                    value={formik.values.lastECGYear}
                    width='100%'
                  />
                  <Select
                    placeholder='Resultado'
                    dense
                    name='resultNormal'
                    disabled={!isEditable(kind, 'resultNormal')}
                    options={[
                      { displayValue: 'Normal', value: true, key: 'NORMAL' },
                      { displayValue: 'Anormal', value: false, key: 'ANORMAL' },
                    ]}
                    onValueChange={onChange}
                    value={formik.values.resultNormal}
                    width='100%'
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={classes.verticalGap}>
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '1.1rem',
              color: '#707070',
              marginBottom: 10,
            }}
          >
            Motivo
          </Typography>
          <Select
            size='medium'
            dense
            name='checkReason'
            disabled={!isEditable(kind, 'checkReason')}
            options={checkReasonOptions.map(opt => (
              { key: opt, displayValue: toTitleCase(opt), value: opt }
            ))}
            value={formik.values.checkReason}
            onValueChange={onChange}
            width='100%'
          />
        </div>
      </div>
      <div name='specs' className={classes.verticalGap}>
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: '1.2rem',
            color: '#486581',
          }}
        >
          ESPECIFICACIONES DEL ESTUDIO
        </Typography>
        <div className={classes.verticalGap}>
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '1.1rem',
              color: '#707070',
            }}
          >
            Autorización de terceros
          </Typography>
          <div className={classes.fullWidth}>
            <div className={classes.column}>
              <Switch
                name='thirdAuthorization'
                disabled={!isEditable(kind, 'thirdAuthorization')}
                checked={formik.values.thirdAuthorization}
                onChange={async event => {
                  if (!event.target.checked) {
                    await formik.setValues({
                      ...formik.values,
                      thirdName: '',
                      relationship: '',
                      thirdAuthorization: false,
                    })
                  }
                  onChange(event)
                }}
              />
              {formik.values.thirdAuthorization && (
                <div className={classes.thirdData}>
                  <TextField
                    margin='dense'
                    placeholder='Nombre'
                    disabled={!isEditable(kind, 'thirdName')}
                    name='thirdName'
                    rounded
                    dense
                    fullWidth
                    value={formik.values.thirdName}
                    onValueChange={onChange}
                  />
                  <TextField
                    margin='dense'
                    placeholder='Parentesco'
                    disabled={!isEditable(kind, 'relationship')}
                    name='relationship'
                    rounded
                    dense
                    fullWidth
                    value={formik.values.relationship}
                    onValueChange={onChange}
                  />
                  <div className={classes.fullWidth}>
                    <TextField
                      margin='dense'
                      placeholder='Email'
                      disabled={!isEditable(kind, 'thirdEmail')}
                      type='email'
                      name='thirdEmail'
                      rounded
                      dense
                      fullWidth
                      value={formik.values.thirdEmail}
                      onBlur={formik.handleBlur}
                      error={formik.touched.thirdEmail
                        && formik.touched.thirdEmail
                        && Boolean(formik.errors.thirdEmail && formik.errors.thirdEmail)
                      }
                      helperText={formik.touched.thirdEmail && formik.touched.thirdEmail && formik.errors.thirdEmail && formik.errors.thirdEmail}
                      helperTextStyles={{ color: 'red', fontWeight: 800 }}
                      onValueChange={onChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={classes.verticalGap}>
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '1.1rem',
              color: '#707070',
            }}
          >
            Urgente
          </Typography>
          <div className={classes.fullWidth}>
            <div className={classes.row}>
              <Switch
                name='urgency'
                disabled={!isEditable(kind, 'urgency')}
                checked={formik.values.urgency}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
        <div className={classes.verticalGap}>
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '1.1rem',
              color: '#707070',
            }}
          >
            Interpretación
          </Typography>
          <div className={classes.fullWidth}>
            <div className={classes.row}>
              <Switch
                name='withInterpretation'
                disabled={!isEditable(kind, 'withInterpretation')}
                checked={formik.values.withInterpretation}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
        <div className={classes.verticalGap}>
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '1.1rem',
              color: '#707070',
            }}
          >
            Observaciones
          </Typography>
          <TextField
            placeholder='Observaciones'
            name='observations'
            fullWidth
            value={formik.values.observations}
            onValueChange={onChange}
            multiline
          />
        </div>
        <div className={classes.verticalGap}>
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '1.1rem',
              color: '#707070',
            }}
          >
            Encargado del estudio
          </Typography>
          <TextField
            placeholder='Nombre completo'
            name='isResponsible'
            disabled={!isEditable(kind, 'isResponsible')}
            fullWidth
            value={formik.values.isResponsible}
            onValueChange={onChange}
            multiline
          />
        </div>
        <div className={classes.verticalGap}>
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '1.1rem',
              color: '#707070',
            }}
          >
            Folio
          </Typography>
          <TextField
            placeholder='Folio'
            name='studioId'
            disabled={!isEditable(kind, 'studioId')}
            fullWidth
            value={formik.values.studioId}
            onValueChange={onChange}
            multiline
          />
        </div>
      </div>
      <div name='parameters' className={classes.verticalGap}>
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: '1.2rem',
            color: '#486581',
          }}
        >
          PARÁMETROS
        </Typography>
        <div name='rythm-and-freq' className={classes.verticalGap}>
          <div className={classes.column}>
            <Typography
              style={{
                fontWeight: 'bold',
                fontSize: '1.1rem',
                color: '#707070',
              }}
            >
              Frecuencia
            </Typography>
            <Select
              placeholder='Tipo de frecuencia'
              displayEmpty
              size='medium'
              dense
              name='frequencyUnit'
              disabled={!isEditable(kind, 'frequencyUnit')}
              options={frequencyUnitsOptions.map(unit => (
                { key: unit, displayValue: toTitleCase(unit), value: unit }
              ))}
              value={formik.values.frequencyUnit}
              onValueChange={onChange}
              width='100%'
            />
          </div>
          <div style={{ minWidth: '300px' }}>
            <TextField
              margin='dense'
              fullWidth
              placeholder='Frecuencia'
              name='frequency'
              disabled={!isEditable(kind, 'frequency')}
              type='number'
              adornmentPosition='end'
              endAdornmentText={formik.values.frequencyUnit === 'FRECUENCIA' ? 'lpm' : 'fvm'}
              rounded
              dense
              value={formik.values.frequency}
              onValueChange={onChange}
            />
          </div>
          <div className={classes.column}>
            <Typography
              style={{
                fontWeight: 'bold',
                fontSize: '1.1rem',
                color: '#707070',
              }}
            >
              Ritmo Cardiaco
            </Typography>
            <Select
              displayEmpty
              size='medium'
              dense
              name='rythm'
              disabled={!isEditable(kind, 'rythm')}
              options={rythmOptions.map(opt => (
                { key: opt, displayValue: toTitleCase(opt), value: opt }
              ))}
              value={formik.values.rythm}
              onValueChange={onChange}
              width='100%'
            />
          </div>
        </div>
        {[0, 1, 2].includes(kind) && ecg?.centerInfo?.pdfKind === 'textract_gateway' && (
          <div name='experimental-textract-info' className={classes.verticalGap}>
            <Alert severity='info'>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 10, alignItems: 'left' }}>
                Datos extraídos con AWS Textract (experimental). Favor de verificarlos manualmente.
                <Button onClick={() => setOcrReportDialogOpen(true)} size='small' variant='outlined' color='info'>
                  Reportar problema
                </Button>
              </div>
            </Alert>
          </div>
        )}
        <div name='axis-data' className={classes.verticalGap}>
          <div className={classes.column}>
            <div name='p-axis' className={classes.verticalGap}>
              <div className={classes.rowSpaceBetween}>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                    color: '#707070',
                  }}
                >
                  Eje de P
                </Typography>
                <Checkbox
                  size='medium'
                  labelPlacement='start'
                  label='No aplica'
                  name='pAxis'
                  checked={paramsDisabled.pAxis}
                  onChange={handleDoesntApplyCheckboxChange}
                  disabled={!isEditable(kind, 'pAxis')}
                />
              </div>
              <TextField
                margin='dense'
                fullWidth
                name='pAxis'
                placeholder={paramsDisabled.pAxis ? 'No aplica' : 'Eje de P'}
                disabled={!isEditable(kind, 'pAxis') || paramsDisabled.pAxis}
                adornmentPosition='end'
                endAdornmentText='grados'
                type={paramsDisabled.pAxis ? 'text' : 'number'}
                rounded
                dense
                value={formik.values.pAxis}
                onValueChange={onChange}
              />
            </div>
            <div name='qrs-axis' className={classes.verticalGap}>
              <div className={classes.rowSpaceBetween}>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                    color: '#707070',
                  }}
                >
                  Eje de QRS
                </Typography>
                <Checkbox
                  size='medium'
                  labelPlacement='start'
                  label='No aplica'
                  name='qrsAxis'
                  checked={paramsDisabled.qrsAxis}
                  onChange={handleDoesntApplyCheckboxChange}
                  disabled={!isEditable(kind, 'qrsAxis')}
                />
              </div>
              <TextField
                placeholder={paramsDisabled.qrsAxis ? 'No aplica' : 'Eje de QRS'}
                disabled={!isEditable(kind, 'qrsAxis') || paramsDisabled.qrsAxis}
                type={paramsDisabled.qrsAxis ? 'text' : 'number'}
                margin='dense'
                adornmentPosition='end'
                endAdornmentText='grados'
                fullWidth
                name='qrsAxis'
                rounded
                dense
                value={formik.values.qrsAxis}
                onValueChange={onChange}
              />
            </div>
            <div name='qrs-axis' className={classes.verticalGap}>
              <div className={classes.rowSpaceBetween}>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                    color: '#707070',
                  }}
                >
                  Eje de T
                </Typography>
                <Checkbox
                  size='medium'
                  labelPlacement='start'
                  label='No aplica'
                  name='tAxis'
                  checked={paramsDisabled.tAxis}
                  onChange={handleDoesntApplyCheckboxChange}
                  disabled={!isEditable(kind, 'tAxis')}
                />
              </div>
              <TextField
                placeholder={paramsDisabled.tAxis ? 'No aplica' : 'Eje de T'}
                disabled={!isEditable(kind, 'tAxis') || paramsDisabled.tAXIS}
                type={paramsDisabled.tAxis ? 'text' : 'number'}
                adornmentPosition='end'
                endAdornmentText='grados'
                margin='dense'
                fullWidth
                name='tAxis'
                rounded
                dense
                value={formik.values.tAxis}
                onValueChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div name='intervals' className={classes.verticalGap}>
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: '1.2rem',
            color: '#486581',
          }}
        >
          INTERVALOS
        </Typography>
        <div name='r-and-p' className={classes.verticalGap}>
          <div className={classes.column}>
            <div name='r-and-r' className={classes.verticalGap}>
              <div className={classes.rowSpaceBetween}>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                    color: '#707070',
                  }}
                >
                  R-R
                </Typography>
                <Checkbox
                  size='medium'
                  labelPlacement='start'
                  label='No aplica'
                  name='rR'
                  checked={paramsDisabled.rR}
                  onChange={handleDoesntApplyCheckboxChange}
                  disabled={!isEditable(kind, 'rR')}
                />
              </div>
              <TextField
                placeholder={paramsDisabled.rR ? 'No aplica' : 'R-R'}
                disabled={!isEditable(kind, 'rR') || paramsDisabled.rR}
                type={paramsDisabled.rR ? 'text' : 'number'}
                margin='dense'
                fullWidth
                adornmentPosition='end'
                endAdornmentText='ms'
                name='rR'
                rounded
                dense
                value={formik.values.rR}
                onValueChange={onChange}
              />
            </div>
            <div name='p-and-r' className={classes.verticalGap}>
              <div className={classes.rowSpaceBetween}>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                    color: '#707070',
                  }}
                >
                  P-R
                </Typography>
                <Checkbox
                  size='medium'
                  labelPlacement='start'
                  label='No aplica'
                  name='pR'
                  checked={paramsDisabled.pR}
                  onChange={handleDoesntApplyCheckboxChange}
                  disabled={!isEditable(kind, 'pR')}
                />
              </div>
              <TextField
                placeholder={paramsDisabled.pR ? 'No aplica' : 'P-R'}
                disabled={!isEditable(kind, 'pR') || paramsDisabled.pR}
                type={paramsDisabled.pR ? 'text' : 'number'}
                margin='dense'
                fullWidth
                adornmentPosition='end'
                endAdornmentText='ms'
                name='pR'
                rounded
                dense
                value={formik.values.pR}
                onValueChange={onChange}
              />
            </div>
          </div>
        </div>
        <div name='q-values' className={classes.verticalGap}>
          <div className={classes.column}>
            <div name='q-r-s' className={classes.verticalGap}>
              <div className={classes.rowSpaceBetween}>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                    color: '#707070',
                  }}
                >
                  QRS
                </Typography>
                <Checkbox
                  size='medium'
                  labelPlacement='start'
                  label='No aplica'
                  name='qrs'
                  checked={paramsDisabled.qrs}
                  onChange={handleDoesntApplyCheckboxChange}
                  disabled={!isEditable(kind, 'qrs')}
                />
              </div>
              <TextField
                placeholder={paramsDisabled.qrs ? 'No aplica' : 'QRS'}
                disabled={!isEditable(kind, 'qrs') || paramsDisabled.qrs}
                type={paramsDisabled.qrs ? 'text' : 'number'}
                margin='dense'
                fullWidth
                name='qrs'
                adornmentPosition='end'
                endAdornmentText='ms'
                rounded
                dense
                value={formik.values.qrs}
                onValueChange={onChange}
              />
            </div>
            <div name='q-t-m' className={classes.verticalGap}>
              <div className={classes.rowSpaceBetween}>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                    color: '#707070',
                  }}
                >
                  QTm
                </Typography>
                <Checkbox
                  size='medium'
                  labelPlacement='start'
                  label='No aplica'
                  name='qtm'
                  checked={paramsDisabled.qtm}
                  onChange={handleDoesntApplyCheckboxChange}
                  disabled={!isEditable(kind, 'qtm')}
                />
              </div>
              <TextField
                placeholder={paramsDisabled.qtm ? 'No aplica' : 'QTm'}
                disabled={!isEditable(kind, 'qtm') || paramsDisabled.qtm}
                type={paramsDisabled.qtm ? 'text' : 'number'}
                margin='dense'
                fullWidth
                name='qtm'
                adornmentPosition='end'
                endAdornmentText='ms'
                rounded
                dense
                value={formik.values.qtm}
                onValueChange={onChange}
              />
            </div>
            <div name='q-t-c' className={classes.verticalGap}>
              <div className={classes.rowSpaceBetween}>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                    color: '#707070',
                  }}
                >
                  QTc
                </Typography>
                <Checkbox
                  size='medium'
                  labelPlacement='start'
                  label='No aplica'
                  name='qtc'
                  checked={paramsDisabled.qtc}
                  onChange={handleDoesntApplyCheckboxChange}
                  disabled={!isEditable(kind, 'qtc')}
                />
              </div>
              <TextField
                placeholder={paramsDisabled.qtc ? 'No aplica' : 'QTc'}
                disabled={!isEditable(kind, 'qtc') || paramsDisabled.qtc}
                type={paramsDisabled.qtc ? 'text' : 'number'}
                margin='dense'
                fullWidth
                name='qtc'
                adornmentPosition='end'
                endAdornmentText='ms'
                rounded
                dense
                value={formik.values.qtc}
                onValueChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div name='observations' className={classes.verticalGap}>
        <div name='st-t' className={classes.verticalGap}>
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '1.1rem',
              color: '#707070',
            }}
          >
            ST-T
          </Typography>
          <TextField
            margin='dense'
            fullWidth
            placeholder='stT'
            name='stT'
            disabled={!isEditable(kind, 'stT')}
            rounded
            dense
            value={formik.values.stT}
            onValueChange={onChange}
          />
        </div>
      </div>
      <div name='conclusions-div' className={classes.verticalGap}>
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: '1.2rem',
            color: '#486581',
          }}
        >
          CONCLUSIONES
        </Typography>
        <TextField
          placeholder='Conclusiones'
          name='diagnosis'
          disabled={!isEditable(kind, 'diagnosis')}
          fullWidth
          value={formik.values.diagnosis}
          onValueChange={onChange}
          multiline
        />
        <div name='conclusions-box' className={classes.verticalGap}></div>
      </div>
      <div className={classes.verticalGap}>
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: '1.2rem',
            color: '#486581',
          }}
        >
          NOTAS
        </Typography>
        <TextField
          placeholder='Notas'
          name='notes'
          disabled={!isEditable(kind, 'notes')}
          fullWidth
          value={formik.values.notes}
          onValueChange={onChange}
          multiline
        />
      </div>
      <div className={classes.verticalGap}>
        <Actions
          id={ecg?.id}
          formik={formik}
          classes={classes}
          onSubmit={onSubmit}
          doUpdateUrl={doUpdateUrl}
          confirmationDialogPref={confirmationDialogPref}
        />
      </div>
    </form>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectKind',
    'selectUnsavedEcgs',
    'selectEcgDialogUnsaved',
    'doEditECG',
    'selectUserPreferences',
    'doSetSelectedEcg',
    'doSetSnackbarFail',
    'doUpdateUrl',
    'doResetEcgDialogData',
    'doSendOcrErrorReport',
  )
  const { userPreferences, unsavedEcgs, ecgDialogUnsaved } = connectedProps
  const confirmationDialogPref = userPreferences?.find(userPref => userPref?.preference?.key === 'ECG_CONFIRMATION_DIALOG')
  let { ecg } = props
  const unsavedData = hasUnsavedData(ecg, unsavedEcgs)
  if (unsavedData) ecg = unsavedData
  return (
    <PatientDataFormEdit
      {...props}
      {...connectedProps}
      ecg={ecg}
      isUnsaved={ecgDialogUnsaved}
      confirmationDialogPref={confirmationDialogPref}
    />
  )
}
