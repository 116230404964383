import React, { useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { styles } from './styles'
import Drawer from './Drawer'
import Grow from '@mui/material/Grow'
import { useConnect } from 'redux-bundler-hook'
import WebNavbar from '../components/WebNavbar'
import MessageDrawer from './MessageDrawer'
import Preferences from '../components/Dialogs/Preferences'
import StatsForNerds from '../components/Dialogs/StatsForNerds'

const useStyles = makeStyles(styles, { name: 'layout' })

const Layout = props => {
  const {
    children,
    kind,
    userFlags = [],
    drawerOpen,
    doOpenDrawer,
    doCloseDrawer,
  } = props
  const classes = useStyles()
  const hasMessagingFlag = userFlags?.includes('MESSAGING_V1')

  useEffect(() => {
    if (!('Notification' in window)) {
      return
    } else if (Notification.permission !== 'granted' && Notification.permission !== 'denied') {
      Notification.requestPermission()
    }
  }, [children])

  return (
    <Grow in>
      <div className={classes.layoutDiv}>
        <Preferences />
        {kind === 0 && <StatsForNerds />}
        <WebNavbar {...props} />
        <div className={classes.content}>
          {children}
        </div>
        <Drawer open={drawerOpen} doOpenDrawer={doOpenDrawer} doCloseDrawer={doCloseDrawer} />
        {hasMessagingFlag && <MessageDrawer />}
      </div>
    </Grow>
  )
}

export default props => {
  const connectedProps = useConnect(
    'doLogout',
    'selectKind',
    'selectDrawerOpen',
    'selectUserFlags',
    'selectAllConversationsUnreads',
    'doOpenConversations',
    'doOpenDrawer',
    'doUpdateUrl',
    'doCloseDrawer',
    'doOpenPreferences',
    'doOpenStatsForNerdsDialog',
  )
  return <Layout {...props} {...connectedProps} />
}
