import React from 'react'
import SearchBar from '../components/common/SearchBar'
import NotificationsIcon from '@mui/icons-material/Notifications'
import Profile from '../components/layout/Profile'
import Button from '../components/common/Button'
import Select from '../components/common/Select'
// import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
// import { IconButton } from '@mui/material'
import TableWeb from '../components/TableWeb'
import { useConnect } from 'redux-bundler-hook'
import EditDialog from '../components/TableWeb/EditDialog'
import { filterOptionsByKind } from '../bundles/filters'
import { Badge, IconButton } from '@mui/material'
import NotificationsTray from '../components/NotificationsTray'
import UploadFileDialog from '../components/Dialogs/UploadFileDialog'
import FileBrowser from '../components/Dialogs/FileBrowser'

const kindSelectorOpts = [
  { key: 'admin', displayValue: 'Asistente', value: 1 },
  { key: 'doctor', displayValue: 'Médico', value: 2 },
  { key: 'center', displayValue: 'Centro', value: 3 },
]
if (globalThis.localStorage.getItem('iAmDev')) kindSelectorOpts.push({ key: 'developer', displayValue: 'DEVELOPER', value: 0 })

const WebApp = props => {
  const {
    ecgs,
    ecgsRaw,
    kind,
    user,
    doOpenDrawer,
    doSetSearchValue,
    filterValue,
    doUpdateFilter,
    doChangeProfileKind,
    doSetEcgsLoading,
    doSetEcgsIdle,
    ...rest
  } = props

  const {
    openEcgDialog,
    ecgsLoading,
    ecgSearchValue,
    currentEcg,
    userId,
    loadingSelectedEcg,
    pdfKey,
    ecgDialogHistoricEcgs,
    ecgDialogChangeHistories,
    doFetchEcgDialogData,
    doCloseEcgDialog,
    doUpdateUrl,
    activeNotifications,
    doAddFile,
    doMarkEcgAsRead,
    doMarkEcgAsUnread,
  } = rest
  const isSuperuser = user?.isSuperuser
  const [anchorEl, setAnchorEl] = React.useState(null)
  const filterOptions = filterOptionsByKind[kind] ?? []

  return (
    <div>
      <UploadFileDialog />
      <FileBrowser />
      <EditDialog
        open={openEcgDialog}
        onClose={doCloseEcgDialog}
        ecgData={currentEcg}
        loading={loadingSelectedEcg}
        pdfKey={pdfKey}
        doUpdateUrl={doUpdateUrl}
        historicEcgs={ecgDialogHistoricEcgs}
        ecgChangeHistories={ecgDialogChangeHistories}
        doFetchEcgDialogData={doFetchEcgDialogData}
        kind={kind}
        doAddFile={doAddFile}
        doMarkEcgAsUnread={doMarkEcgAsUnread}
        doMarkEcgAsRead={doMarkEcgAsRead}
        userId={userId}
      />
      <NotificationsTray anchorEl={anchorEl} onClose={() => setAnchorEl(null)} />
      <Profile fontSize='1.5rem' iconSize='2rem' fontColor='#707070' iconColor='#707070' />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'center',
            width: '100%',
            minWidth: '50%',
            gap: 10,
          }}
        >
          <div style={{ width: '40%' }}>
            <SearchBar
              placeholder='Buscar...'
              searchValue={ecgSearchValue}
              setSearchValue={doSetSearchValue}
              loading={ecgsLoading}
              debounceTime={600}
              setIsLoading={doSetEcgsLoading}
              setIsIdle={doSetEcgsIdle}
            />
          </div>
          <Select
            size='small'
            placeholder='Filtro'
            options={filterOptions.map(opt => (
              { key: opt.value, displayValue: opt.displayValue, value: opt.value }
            ))}
            value={filterValue}
            onValueChange={e => doUpdateFilter(e.target.value)}
            placeholderStyle={{ color: '#F94654' }}
            width='200px'
            dense
          />
          {/* <IconButton sx={{ marginLeft: 10 }}>
            <HelpOutlineIcon />
          </IconButton> */}
          {isSuperuser && process.env.REACT_APP_DEBUG === 'true' && (
            <Select
              displayEmpty
              dense
              value={kind}
              options={kindSelectorOpts}
              width='200px'
              onValueChange={e => doChangeProfileKind(e.target.value)}
            />
          )}
          <IconButton size='medium' onClick={e => setAnchorEl(e.currentTarget)}>
            <Badge
              badgeContent={activeNotifications}
              color='error'
              sx={{
                fontFamily: 'roboto',
              }}
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </div>
        {(kind === 3 || kind === 0) && (
          <div>
            <Button styles={{ height: 40, width: 150 }} onClick={doOpenDrawer}>
              Nuevo estudio
            </Button>
          </div>
        )}
      </div>
      <div style={{ marginTop: 30, width: '100%', height: 'calc(100vh - 200px)' }}>
        <TableWeb
          data={ecgs}
          ecgsRaw={ecgsRaw}
          kind={kind}
          {...rest}
        />
      </div>
    </div>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectEcgs',
    'selectEcgSearchValue',
    'selectUser',
    'selectUserCenter',
    'selectUserId',
    'selectKind',
    'selectEcgsRaw',
    'selectOpenEcgDialog',
    'selectRouteParams',
    'selectUserFlags',
    'selectPdfKey',
    'selectFilterValue',
    'selectCurrentEcg',
    'selectEcgDialogHistoricEcgs',
    'selectEcgDialogChangeHistories',
    'selectEcgsLoading',
    'selectLoadingSelectedEcg',
    'selectActiveNotifications',
    'selectUserPreferences',
    'doSetEcgsLoading',
    'doSetEcgsIdle',
    'doOpenUploadComponent',
    'doCloseUploadComponent',
    'doOpenDrawer',
    'doSetSelectedEcgId',
    'doSetSelectedEcg',
    'doOpenEcgDialog',
    'doUpdateUrl',
    'doCloseEcgDialog',
    'doFetchEcgDialogData',
    'doSetSearchValue',
    'doUpdateFilter',
    'doChangeProfileKind',
    'doSetOpenedFromTable',
    'doSetEcgsCurrentPage',
    'doSetEcgsPageSize',
    'doAddFile',
    'doMarkEcgAsRead',
    'doMarkEcgAsUnread',
    'doOpenConversations',
    'doFetchEcgChangeHistory',
  )

  return <WebApp {...connectedProps} {...props} />
}
