import axios from 'axios'
import { createSelector } from 'redux-bundler'
// import * as Sentry from '@sentry/react'
import { tokenHeader } from '../utils'
// import LogRocket from 'logrocket'
import {
  sessionReplayEnvs,
  sentryEnvs,
} from '..'
import { timeout } from '../utils/misc'

const kindsById = {
  0: 'Developer',
  1: 'Asistente',
  2: 'Médico',
  3: 'Centro',
}

export const types = {
  USER_LOADING: 'USER_LOADING',
  USER_LOADED: 'USER_LOADED',
  AUTH_ERROR: 'AUTH_ERROR',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN',
  REFRESH_TOKEN_START: 'REFRESH_TOKEN_START',
  REFRESH_TOKEN_FAIL: 'REFRESH_TOKEN_FAIL',
  CHANGE_PROFILE_KIND: 'CHANGE_PROFILE_KIND',
  GET_USERS: 'GET_USERS',
  GET_USERS_ERROR: 'GET_USERS_ERROR',
  SET_USERS_LOADING: 'SET_USERS_LOADING',
  SET_USERS_IDLE: 'SET_USERS_IDLE',
  SET_SESSION_REPLAY_LOADED: 'SET_SESSION_REPLAY_LOADED',
}

const initialState = {
  access: localStorage.getItem('access'),
  refresh: localStorage.getItem('refresh'),
  isAuthenticated: false,
  loading: false,
  user: null,
  lastFetch: null,
  lastError: null,
  users: [],
  userError: false,
  usersError: false,
  sessionReplayLoaded: false,
}

export default {
  name: 'auth',
  reducer: (state = initialState, action) => {
    switch (action.type) {
    case types.SET_USERS_LOADING:
    case types.USER_LOADING:
    case types.REFRESH_TOKEN_START:
      return {
        ...state,
        loading: true,
      }
    case types.REFRESH_TOKEN_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        user: null,
        access: null,
        refresh: null,
      }
    case types.SET_USERS_IDLE:
      return {
        ...state,
        loading: false,
      }
    case types.USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.payload,
        lastFetch: Date.now(),
        userError: false,
      }
    case types.LOGIN_SUCCESS:
      localStorage.setItem('access', action.payload.access)
      localStorage.setItem('refresh', action.payload.refresh)
      localStorage.setItem('userId', action.payload.user.user.id)
      localStorage.setItem('kind', action.payload.user.kind)
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        loading: false,
        lastFetch: Date.now(),
        userError: false,
      }
    case types.AUTH_ERROR:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        loading: false,
        access: null,
        refresh: null,
        lastError: Date.now(),
        userError: true,
      }
    case types.LOGIN_FAIL:
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        loading: false,
        access: null,
        refresh: null,
        lastFetch: null,
        users: null,
      }
    case types.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        access: localStorage.getItem('access'),
        isAuthenticated: true,
        loading: false,
      }
    case types.CHANGE_PROFILE_KIND:
      return {
        ...state,
        user: {
          ...state?.user,
          kind: action.payload,
        }
      }
    case types.GET_USERS:
      return {
        ...state,
        users: action.payload,
        usersError: false,
      }
    case types.GET_USERS_ERROR:
      return {
        ...state,
        usersError: true,
      }
    case types.SET_SESSION_REPLAY_LOADED:
      return {
        ...state,
        sessionReplayLoaded: action.payload,
      }
    default:
      return state
    }
  },
  selectAuth: state => state.auth,
  selectUserFlags: state => state.auth.user?.flags,
  selectSessionReplayLoaded: state => state.auth.sessionReplayLoaded,
  selectUserPreferences: state => state.auth.user?.preferences,
  selectAccessToken: state => state.auth.access,
  selectIsAuthenticated: state => state.auth.isAuthenticated,
  selectUserCenter: state => state.auth.user?.center,
  selectUserCenterId: state => state.auth.user?.center?.id,
  selectUserCenterOrganization: state => state.auth.user?.center?.organization,
  selectUserCenterOrganizationId: state => state.auth.user?.center?.organization?.id,
  selectUsers: state => state.auth.users,
  selectUserId: state => state.auth.user?.user?.id,
  selectUser: state => state.auth.user?.user,
  selectKind: state => state.auth.user?.kind,
  selectAuthLoading: state => state.auth.loading,
  doLoadUser: () => async ({ dispatch, store }) => {
    dispatch({ type: types.USER_LOADING })
    const access = store.selectAccessToken()
    const sessionReplayLoaded = store.selectSessionReplayLoaded()
    let res
    try {
      res = await axios.get('/api/auth/user', tokenHeader(access))
      dispatch({
        type: types.USER_LOADED,
        payload: res.data,
      })
      const sessionReplayEnable = res.data.sessionReplayEnable ?? false
      const username = res.data.user.username
      const email = res.data.user.email
      const kind = res.data.kind
      const fullName = res.data.user.fullName
      const userId = res.data.user.id
      const center = res.data?.center?.name ?? 'NO CENTER'
      const centerId = res.data?.center?.id ?? 'NO CENTER'
      globalThis.localStorage.setItem('kind', kind)

      // const configObj = {
      //   name: fullName,
      //   email,
      //   // Custom user variables here
      //   kind: kindsById[kind],
      //   userId,
      //   center,
      //   centerId,
      //   username,
      //   environment: process.env.REACT_APP_ENVIRONMENT,
      // }

      if (kind === 2) store.doUpdateFilter('inProgress')
      if (sentryEnvs.includes(process.env.REACT_APP_ENVIRONMENT)) {
        // After the user has been authenticated
        // Sentry.setUser({
        //   id: userId,
        //   email,
        //   username,
        // })
      }
      if (sessionReplayEnvs.includes(process.env.REACT_APP_ENVIRONMENT) && sessionReplayEnable && !sessionReplayLoaded) {
        const configObj = {
          apiKey: process.env.REACT_APP_SESSION_REPLAY_APIKEY,
          startRecording: true,
          userInfo: {
            userId: username,
            userName: fullName,
          },
          sessionInfo: {
            dbId: userId,
            email,
            kind: kindsById[kind],
            fullName,
            center,
            centerId,
          },
        }
        var w = window
        w.SessionRewindConfig = configObj

        var f = document.createElement('script')
        f.async = 1
        f.crossOrigin = 'anonymous',
        f.src = 'https://rec.sessionrewind.com/srloader.js'

        var g = document.getElementsByTagName('head')[0]
        g.insertBefore(f, g.firstChild)
        globalThis.localStorage.setItem('sessionReplay', JSON.stringify(configObj))
        dispatch({ type: types.SET_SESSION_REPLAY_LOADED, payload: true })
      }
      if (kind === 0) store.doFetchStatsForNerds()
    } catch (error) {
      const ecgWebsocket = globalThis['ecgWebsocket']
      if (ecgWebsocket) {
        ecgWebsocket.close()
        ecgWebsocket.onclose()
      }
      dispatch({ type: types.AUTH_ERROR })
      const clientVersion = localStorage.getItem('latestVersion')
      localStorage.clear()
      localStorage.setItem('latestVersion', clientVersion)
    }
  },
  doLogin: (username, password) => async ({ dispatch, store }) => {
    store.doSetAppLoading()
    dispatch({ type: types.SET_USERS_LOADING })
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const body = JSON.stringify({ username, password })
    let res
    try {
      res = await axios.post('/api/auth/login', body, config)
      dispatch({
        type: types.LOGIN_SUCCESS,
        payload: res.data,
      })
      const username = res.data.user.user.username
      store.doSetSnackbarSuccess(`Bienvenid@, ${username}`)
      store.doSetAppIdle()
      store.doUpdateUrl('/')
      dispatch({ type: types.SET_USERS_LOADING })
      await store.doLoadUser()
      await store.doFetchServerInfo()
      await store.doFetchPDFKey()
      await store.doFetchBaseURL()
      dispatch({ type: types.SET_USERS_IDLE })
    } catch (err) {
      dispatch({ type: types.LOGIN_FAIL })
      const clientVersion = localStorage.getItem('latestVersion')
      localStorage.clear()
      localStorage.setItem('latestVersion', clientVersion)
      if (err.response.data.error) {
        store.doSetSnackbarFail(String(err.response.data.error))
      }
      else if (err.response.data.non_field_errors) {
        const errors = err.response.data.non_field_errors.join(' ')
        store.doSetSnackbarFail(errors)
      } else store.doSetSnackbarFail('Usuario o contraseña incorrectos.')
      store.doSetAppIdle()
      dispatch({ type: types.SET_USERS_IDLE })
    }
  },
  doLogout: (showMessage = true) => ({ dispatch, store }) => {
    const ecgWebsocket = globalThis['ecgWebsocket']
    if (ecgWebsocket) {
      ecgWebsocket.close()
      ecgWebsocket.onclose()
    }
    if (showMessage) store.doSetSnackbarInfo('Sesión cerrada correctamente')
    store.doSetCachedEcgs({})
    store.doClearCachedConversations()
    store.doSetEcgsInitialState()
    store.doClearServerInfo()
    store.doClearConversationsData()
    store.doSetEcgDialogInitialState()
    globalThis.localStorage.setItem('cachedEcgs', JSON.stringify({}))
    globalThis.localStorage.setItem('unsavedEcgs', JSON.stringify({}))
    globalThis.localStorage.setItem('cachedServerInfo', JSON.stringify({}))
    globalThis.localStorage.setItem('logrocket', JSON.stringify({}))
    globalThis.document.title = 'ECG - Iniciar Sesión'
    dispatch({ type: types.LOGOUT_SUCCESS })
    const clientVersion = localStorage.getItem('latestVersion')
    localStorage.clear()
    localStorage.setItem('latestVersion', clientVersion)
    // Sentry.setUser(null)
  },
  doFetchUsers: () => async ({ dispatch, store }) => {
    dispatch({ type: types.SET_USERS_LOADING })
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.get('/api/users/', tokenHeader(access))
      dispatch({ type: types.GET_USERS, payload: res.data })
      dispatch({ type: types.SET_USERS_IDLE })
    } catch (error) {
      dispatch({ type: types.GET_USERS_ERROR })
      dispatch({ type: types.SET_USERS_IDLE })
    }
  },
  doChangeProfileKind: kind => async ({ dispatch, store }) => {
    dispatch({ type: types.SET_USERS_LOADING })
    const access = store.selectAccessToken()
    const userId = store.selectUserId()
    let res
    try {
      res = await axios.post(`/api/profile/${userId}/change/`, { kind }, tokenHeader(access))
      dispatch({
        type: types.CHANGE_PROFILE_KIND,
        payload: res.data,
      })
      globalThis.location.reload()
      dispatch({ type: types.SET_USERS_IDLE })
    } catch (error) {
      store.doSetSnackbarFail(error.response.data)
      dispatch({ type: types.SET_USERS_IDLE })
    }
  },
  doSendResetLink: user => async ({ store }) => {
    try {
      await axios.post('/api/users/sendEmailResetLink/', { user })
      return true
    } catch (error) {
      const errorResponse = error.response.data
      if ('user' in errorResponse) {
        const errorStrings = errorResponse.user.join('\n')
        store.doSetSnackbarFail(errorStrings)
      } else {
        store.doSetSnackbarFail('Error al enviar link para reestablecer contraseña.')
      }
      return false
    }
  },
  doReportReset: token => async () => {
    try {
      await axios.get(`/api/users/reportResetFraud/?token=${token}`)
      return true
    } catch (error) {
      return false
    }
  },
  doResetPassword: (token, data) => async ({ store }) => {
    try {
      await axios.post(`/api/users/changePassword/?token=${token}`, data)
      store.doSetSnackbarSuccess('Contraseña actualizada correctamente. Redirigiendo al login.')
      timeout(2000)
      store.doUpdateUrl('/login')
    } catch (error) {
      const errorResponse = error.response.data
      if ('error' in errorResponse) {
        store.doSetSnackbarFail(errorResponse.error)
      } else {
        store.doSetSnackbarFail('Error al reestablecer contraseña. Contacte a soporte EF100.')
      }
    }
  },
  reactShouldFetchUser: createSelector(
    'selectAuth',
    'selectIsAuthenticated',
    'selectAppTime',
    'selectIsOnline',
    (authRaw, isAuthenticated, appTime, isOnline) => {
      if (authRaw.loading) return
      if (!isAuthenticated) return
      if (!isOnline) return
      let shouldFetch = false

      if (authRaw.lastFetch) {
        const timePassed = appTime - authRaw.lastFetch
        if (timePassed > (60000 * 3)) {
          shouldFetch = true
        }
      } else {
        shouldFetch = true
      }

      if (authRaw.lastError && authRaw.userError) {
        const timeSinceError = appTime - authRaw.lastError
        if (timeSinceError > 10000) {
          shouldFetch = true
        }
      }
      if (shouldFetch) return { actionCreator: 'doLoadUser' }
    }
  ),
  init: async store => {
    try {
      await store.doLoadUser()
      await store.doFetchUsers()
    } catch (error) {
      if (error.response.status !== 401 && process.env.REACT_APP_DEBUG === 'true') throw error
    }
  },
}
