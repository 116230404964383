import { createRoot } from 'react-dom/client'
import React from 'react'
import { ReduxBundlerProvider as Provider } from 'redux-bundler-hook'
import axios from 'axios'
import App from './routes'
import createStore from './bundles'
import cache from './bundles/cache'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import theme from './theme'
import Globals from './containers/Globals'
import * as Sentry from '@sentry/react'
const pkg = require('../package.json')

export const sessionReplayEnvs = ['production']
export const sentryDsn = process.env.REACT_APP_SENTRY_DSN
export const sentryEnvs = ['production']

// sentry
if (
  sentryEnvs.includes(process.env.REACT_APP_ENVIRONMENT)
  && process.env.REACT_APP_ALLOW_SENTRY === 'true'
) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost:8000', /^https:\/\/api\.centrointegraldiagnostico\.com/],
    // Session Replay
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: pkg?.version,
  })
}

const baseUrl = process.env.REACT_APP_BASE_URL
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.baseURL = baseUrl
axios.defaults.withCredentials = false


const appDiv = document.getElementById('app')
const root = createRoot(appDiv)

cache.getAll().then(initialData => {
  const store = createStore(initialData)
  window.store = store
  root.render(
    <Provider store={store}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Globals />
        <App />
      </ThemeProvider>
    </Provider>,
  )
})
