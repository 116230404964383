import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { IconButton, Tooltip, Typography } from '@mui/material'
import Button from '../common/Button'
import EditIcon from '@mui/icons-material/Edit'
import StatusLed from '../common/StatusLed'
import MarkunreadIcon from '@mui/icons-material/Markunread'
import DraftsIcon from '@mui/icons-material/Drafts'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { stringToMaxLength } from '../../utils/string'
import { getLanguageLocaleDateOptions, numericLocaleDateOptions } from '../../utils/dates'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    borderRadius: 25,
    backgroundColor: 'white',
    padding: 10,
    marginTop: theme => theme.marginTop,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  patientData: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  dateTime: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
  },
  centerInfo: {
    display: 'flex',
    marginLeft: 15,
  },
  editButton: {
    display: 'flex',
    alignContent: 'center',
    padding: 15,
  }
}))

const TablePatientDetail = props => {
  const {
    patientName,
    ecgData,
    isFirstRow,
    doOpenEcgDialog,
    doUpdateUrl,
    doSetSelectedEcgId,
    doFetchEcgDialogData,
    doFetchEcgChangeHistory,
    doSetOpenedFromTable,
    doMarkEcgAsUnread,
    doMarkEcgAsRead,
    isRead,
    setIsRead,
    kind,
    userPreferences,
  } = props
  const theme = { marginTop: isFirstRow ? 30 : 0 }
  const classes = useStyles(theme)

  const ts = new Date(ecgData.createdOn)
  const fullYear = ts.getFullYear()
  const dateDisplayPref = userPreferences.find(userPref => userPref.preference.key === 'DATE_DISPLAY_LONG') ?? { value: false }
  const date = ts.toLocaleDateString(
    undefined,
    dateDisplayPref.value ? getLanguageLocaleDateOptions(fullYear) : numericLocaleDateOptions,
  )
  const time = ts.toLocaleTimeString()
  const interpreted = ecgData.withInterpretation ?  'CON INT.' : 'SIN INT.'
  const condensedCenterName = userPreferences.find(userPref => userPref.preference.key === 'ECG_CENTER_NAME_COLLAPSE') ?? { value: true }

  const centerNameString = condensedCenterName.value
    ? stringToMaxLength(ecgData.centerInfo.name.toUpperCase(), 15)
    : ecgData.centerInfo.name.toUpperCase()

  const centerInfoString = `${centerNameString} / ${interpreted}`
  const tooltipTitle = isRead ? 'Marcar como no leído' : 'Marcar como leído'
  const readAction = isRead ? doMarkEcgAsUnread : doMarkEcgAsRead
  const [readLoading, setReadLoading] = useState(false)

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <StatusLed ecg={ecgData} />
        <div className={classes.patientData}>
          <Typography
            sx={{
              display: 'flex',
              flexDirection: 'row',
              fontWeight: 800,
              color: '#707070',
              fontSize: '18px',
              textDecoration: ecgData.rejected ? 'line-through' : 'none',
              alignItems: 'center',
              gap: 2,
            }}
          >
            {patientName.toUpperCase()}
            {ecgData.hidden && <VisibilityOffIcon fontSize='3px' />}
          </Typography>
          <div className={classes.dateTime}>
            <Typography
              sx={{
                fontWeight: 400,
                color: '#707070',
                fontSize: '12px',
                fontFamily: 'roboto'
              }}
            >
              {date}
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                color: '#707070',
                fontSize: '12px',
                fontFamily: 'roboto'
              }}
            >
              {time}
            </Typography>
            <div className={classes.centerInfo}>
              {condensedCenterName.value ? (
                <Tooltip title={ecgData.centerInfo.name.toUpperCase()}>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      color: '#707070',
                      fontSize: '12px',
                      fontFamily: 'roboto'
                    }}
                  >
                    {centerInfoString}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography
                  sx={{
                    fontWeight: 400,
                    color: '#707070',
                    fontSize: '12px',
                    fontFamily: 'roboto'
                  }}
                >
                  {centerInfoString}
                </Typography>
              )}
            </div>
          </div>
        </div>
      </div>
      <div style={{ alignSelf: 'center' }}>
        <Tooltip arrow title={tooltipTitle} placement='top'>
          <IconButton
            onClick={async () => {
              setReadLoading(true)
              const result = await readAction(ecgData)
              setReadLoading(false)
              if (result) setIsRead(!isRead)
            }}
            disabled={readLoading}
          >
            {isRead ? (
              <MarkunreadIcon fontSize='small' />
            ) : (
              <DraftsIcon fontSize='small' />
            )}
          </IconButton>
        </Tooltip>
      </div>
      {kind !== 2 && (
        <div className={classes.editButton}>
          <Button
            icon={EditIcon}
            style={{
              textTransform: 'none',
              backgroundColor: 'transparent',
              color: '#486581',
              fontWeight: 600,
              fontFamily: 'roboto',
              border: '1px solid #486581',
              borderRadius: '10px',
            }}
            iconStyles={{ marginRight: 1 }}
            disableElevation
            onClick={async () => {
              doSetOpenedFromTable(true)
              doSetSelectedEcgId(ecgData.id)
              await doFetchEcgDialogData(ecgData)
              await doFetchEcgChangeHistory(ecgData)
              doOpenEcgDialog()
              doUpdateUrl(`/estudio/${ecgData.id}/`)
            }}
          >
            Editar
          </Button>
        </div>
      )}
    </div>
  )
}

TablePatientDetail.propTypes = {
  isEditable: PropTypes.bool,
  patientName: PropTypes.string.isRequired,
  ecgData: PropTypes.shape({
    createdOn: PropTypes.string.isRequired,
    centerInfo: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
TablePatientDetail.defaultProps = {
  isEditable: false,
}

export default TablePatientDetail
