import React from 'react'
import { useConnect } from 'redux-bundler-hook'
import Layout from './LayoutWrapper'
import { Helmet } from 'react-helmet'
import Spinner from '../components/common/Spinner'

export const getDataByKey = (params, user, key) => {
  switch (key) {
  case 'username':
    return user?.fullName
  case 'ecgId':
    return params?.ecgId
  default:
    return ''
  }
}

const App = props => {
  const {
    route,
    isAuthenticated,
    authLoading,
    appLoading,
    // kind,
    // doUpdateUrl,
    // appTitle,
    // user,
  } = props
  const {
    component: Component,
    titleTemplate,
    // permission = 3,
  } = route
  // if (permission < kind) {
  //   doUpdateUrl('/not-found')
  //   return null
  // }
  // TODO: add an extra permissions prop to each route and check if the current user (if authenticated) can be admitted
  const pageTitle = `CIDC - ${titleTemplate}`

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {isAuthenticated ? (
        <Layout component={Component} />
      ) : authLoading || appLoading ? (
        <div
          style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      ) : (
        <Component />
      )}
    </>
  )
}

export default () => {
  const connectedProps = useConnect(
    'selectRoute',
    'selectAuthLoading',
    'selectAppLoading',
    'selectPathname',
    'selectIsAuthenticated',
    'doUpdateUrl',
    'selectRouteParams',
    'selectUser',
    'selectAppTitle',
    'selectKind',
  )
  return <App {...connectedProps} />
}
