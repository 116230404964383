import React from 'react'
import Button from '../common/Button'
import { makeStyles } from '@mui/styles'
import { Tooltip, Typography } from '@mui/material'
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes'
import ConfirmationDialog from '../common/ConfirmationDialog'
import { useState } from 'react'
import { noop } from '../../utils/misc'
import { getDisabledByKey, getFillByKey, getIconStylesByKey, iconsByKey } from '../../utils/actionButton'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 30,
  }
}))

const DetailComponent = () => (
  <div style={{ position: 'absolute', marginTop: -30, marginLeft: 15 }}>
    <SpeakerNotesIcon style={{ fill: '#486581', fontSize: '30px' }} />
  </div>
)

const ActionButton = props => {
  const classes = useStyles()
  const {
    action,
    status,
    isFirstRow,
    ecg,
    kind,
  } = props
  const icon = iconsByKey[action.key]
  const fillsByKey = getFillByKey(ecg)
  const disabledByKey = getDisabledByKey(ecg, kind)
  const disabled = disabledByKey[action.key]
  const filled = fillsByKey[action.key]
  const fillColor = filled ? '#486581' : '#E0E0E0'
  const isStatus = action.key === 'status'
  const errorStatus = status === 'red'
  let statusIcon
  if (isStatus) statusIcon = icon[status]
  else statusIcon = icon
  const detail = `${ecg.rejected ? 'Rechazado.' : ''} ${ecg.notes || 'Sin notas.'} ${ecg.diagnosis || 'Sin diagnóstico.'}`
  const actionOnClick = e => {
    e.preventDefault()
    e.stopPropagation()
    action.onClick(action, ecg)
  }
  const nonDeleteOnClick = disabled ? noop : actionOnClick
  const isDelete = action.key === 'delete'
  const [openDelete, setOpenDelete] = useState(false)
  const handleClick = isDelete ? () => setOpenDelete(true) : nonDeleteOnClick
  const iconStyles = getIconStylesByKey({ fillColor })[action.key]

  return (
    <div
      className={classes.root}
    >
      {openDelete && (
        <ConfirmationDialog
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          truthyAction={() => {
            action.onClick(action, ecg)
            setOpenDelete(false)
          }}
          truthyActionStyle={{ backgroundColor: '#DD3C48' }}
          setOpen={setOpenDelete}
          title='¿Eliminar estudio?'
          description={`ID: ${ecg.id} - ${ecg.patient.name}`}
        />
      )}
      <Tooltip placement='top' arrow title={!isStatus ? action.displayName : ''}>
        <div>
          {isFirstRow && (
            <div className={classes.header}>
              <Typography
                sx={{
                  fontSize: 12,
                }}
              >
                {action.displayName}
              </Typography>
            </div>
          )}
          <Button
            icon={statusIcon}
            style={{
              backgroundColor: 'white',
              width: 90,
              borderRadius: '20px',
              height: 80,
            }}
            disableElevation
            disabled={disabled}
            iconProps={{
              fill: fillColor,
              stroke: fillColor,
              width: 27,
            }}
            tooltip={isStatus && (errorStatus || ecg.rejected)}
            tooltipTitle={detail}
            onClick={openDelete ? noop : handleClick}
            iconStyles={iconStyles}
          >
            {isStatus && (errorStatus || ecg.rejected) && (
              <DetailComponent />
            )}
          </Button>
        </div>
      </Tooltip>
    </div>
  )
}

export default ActionButton
