import React from 'react'
import { makeStyles } from '@mui/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { validEcgDocuments } from '../../utils/ecg'
import { stringToMaxLength } from '../../utils/string'
import { IconButton, Tooltip } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useConnect } from 'redux-bundler-hook'

const useStyles = makeStyles(() => ({
  root: props => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: props.isConfirmedTrace ? '#8adaa5' : '#f7f7f7',
    width: props.size || null,
    height: props.size || null,
    '&:hover': {
      backgroundColor:props.isConfirmedTrace ? '#84d19e' : '#F2F2F2',
    },
  }),
  newIcon: props => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#f7f7f7',
    width: props.size || null,
    height: props.size || null,
    '&:hover': {
      backgroundColor: '#F2F2F2',
      cursor: 'pointer',
    },
  }),
  deleteButton: {
  },
}))

const NewFileIcon = props => {
  const {
    size,
    onClick,
    ecgData,
  } = props
  const classes = useStyles({ size })
  const openInput = () => {
    const inp = document.querySelector('#ecg-file-input')
    inp.click()
  }
  const onFileChange = async e => {
    const file = new FormData()
    file.append('file', e.target.files[0])
    file.append('name', e.target.files[0].name)
    await onClick(file, ecgData.id)
  }
  return (
    <div className={classes.newIcon} onClick={openInput}>
      <form style={{ display: 'none'}}>
        <input id={'ecg-file-input'} type='file' multiple={false} onChange={onFileChange} />
      </form>
      <FontAwesomeIcon icon='fa-solid fa-plus' size='3x' color='rgb(0, 0, 0, 0.2)' />
      <p style={{ fontSize: 14, color: '#555555' }}>Agregar archivo</p>
    </div>
  )
}

const FileIcon = props => {
  const {
    isNew = false,
    fileData,
    size,
    kind,
    doDeleteECGDocument,
  } = props
  if (isNew) return <NewFileIcon {...props} />
  const isConfirmedTrace = fileData.name.startsWith('CONFIRMED__')
  const confirmedTraceName = isConfirmedTrace ? fileData.name.split('__')[1].slice(0, 23) : fileData.name
  const classes = useStyles({ size, isConfirmedTrace })
  const title = isConfirmedTrace ? confirmedTraceName : (validEcgDocuments[fileData.name] || fileData.name)
  const goToFile = () => window.open(fileData.filePtr, '_blank')
  return (
    <div className={classes.root} >
      <p style={{ fontSize: 12, color: '#555555' }}>{stringToMaxLength(title, 25)}</p>
      <FontAwesomeIcon icon='fa-solid fa-file' size='3x' color='rgb(0, 0, 0, 0.2)' />
      <div style={{ display: 'inline-block', maxWidth: '90%',  overflowWrap: 'anywhere', textAlign: 'center' }}>
        <p
          onClick={goToFile}
          style={{
            fontSize: 12,
            textOverflow: 'ellipsis',
            textDecoration: 'underline blue',
            color: 'blue',
            cursor: 'pointer',
          }}
          title={isConfirmedTrace ? confirmedTraceName : fileData.name}
        >
          {stringToMaxLength(title, 20)}
        </p>
      </div>
      <p style={{ fontSize: 14, color: '#555555' }}>{fileData.fileExtension}</p>
      {kind <= 1 && (
        <div className={classes.deleteButton}>
          <Tooltip arrow title='Eliminar' placement='top'>
            <IconButton onClick={() => doDeleteECGDocument(fileData.id)}>
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </div>
  )
}

export default props => {
  const connectedProps = useConnect('doDeleteECGDocument')
  return <FileIcon {...props} {...connectedProps} />
}
