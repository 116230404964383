import React from 'react'
import Drawer from '@mui/material/Drawer'
import ConsentForm from '../components/ConsentForm'

export const DrawerComponent = props => {
  const {
    doCloseDrawer,
    doOpenDrawer,
    open,
  } = props

  return (
    <Drawer
      anchor='right'
      BackdropProps={{ style: { backgroundColor: 'black', opacity: 0.1 } }}
      PaperProps={{
        style:
        {
          borderRadius: '60px 0 0 60px',
          padding: '45px',
          minWidth: '40%',
          backgroundColor: 'white',
        },
      }}
      elevation={5}
      open={open}
      onClose={doCloseDrawer}
      onOpen={doOpenDrawer}
    >
      <ConsentForm />
    </Drawer>
  )
}

export default DrawerComponent
